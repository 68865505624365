import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import { removeNamespaces, showMessage, saveFile } from '@/helpers/utils';

import axios from 'axios';
import { api } from '@/helpers/api';
import { APP_DOCTIS, APP_DOCTIS_API_KEY } from '@/app.config';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.TRANSFER_REASONS_FETCH](_, type) {
    NProgress.start();
    try {
      const { data: reasons } = await api.get(`v2/consultations/listReasons?type=${type}`);
      return reasons;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CONSULTATION_HISTORY_FETCH](_, params) {
    NProgress.start();

    try {
      const { data } = await api.get(`v2/consultations/history?consultationId=${params}`);
      NProgress.done();
      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CONSULTATIONS_FETCH]({ commit }, {
    query,
    skip,
    take,
    filter,
    clear,
    save,
  }) {
    NProgress.start();

    if (save && clear) {
      commit(localTypes.CONSULTATIONS_SET, []);
    }

    try {
      const params = new URLSearchParams();

      if (filter) {
        if (filter.from !== null) {
          params.append('From', filter.from);
        }
        if (filter.to !== null) {
          params.append('To', filter.to);
        }
        if (filter.status !== null) {
          params.append('requestStatus', filter.status);
        }
      }

      if (query) {
        params.append('consultationId', query);
      }
      params.append('Skip', skip);
      params.append('Take', take);

      const { data: consultations } = await api.get('v2/consultations', {
        params,
      });

      if (save) {
        commit(localTypes.CONSULTATIONS_ADD, consultations);
      }

      return consultations;
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить консультации',
      });

      throw (err);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CONSULTATIONS_NEW_SEARCH]({ commit }, {
    skip,
    take,
    filter: {
      searchStringPatient,
      searchStringTargetPatient,
      phoneNumber,
      consultationId,
      startCreateDate,
      endCreateDate,
      startSlotDate,
      endSlotDate,
      startCloseDate,
      endCloseDate,
      status,
      type,
      clinicId,
      doctorId,
      specializationId,
    },
    clear,
    save,
  }) {
    NProgress.start();

    if (save && clear) {
      commit(localTypes.CONSULTATIONS_SET, []);
    }

    try {
      const params = {
        skip,
        take,
      };

      if (searchStringPatient) params.searchStringPatient = searchStringPatient;
      if (searchStringTargetPatient) params.searchStringTargetPatient = searchStringTargetPatient;
      if (phoneNumber) params.phoneNumber = phoneNumber;
      if (consultationId) params.consultationId = consultationId;

      // popover filter
      if (startCreateDate) params.startCreateDate = startCreateDate;
      if (endCreateDate) params.endCreateDate = endCreateDate;
      if (startSlotDate) params.startSlotDate = startSlotDate;
      if (endSlotDate) params.endSlotDate = endSlotDate;
      if (startCloseDate) params.startCloseDate = startCloseDate;
      if (endCloseDate) params.endCloseDate = endCloseDate;
      params.status = status ?? undefined;
      if (type) params.type = type;
      if (clinicId) params.clinicId = clinicId;
      if (doctorId) params.doctorId = doctorId;
      if (specializationId) params.specializationId = specializationId;

      const { data: consultations } = await api.post('v2/consultations/newSearch', params);

      if (save) {
        commit(localTypes.CONSULTATIONS_ADD, consultations);
      }

      return consultations;
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить консультации',
      });

      throw (err);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CONSULTATIONS_TRANSFER]({ rootState, dispatch }, transferObject) {
    NProgress.start();

    try {
      await axios.post(
        `${APP_DOCTIS}Consultation/Transfer`,
        transferObject,
        {
          headers: {
            ApiKey: APP_DOCTIS_API_KEY,
            CrmProfileId: typeof rootState.Auth.user.userId === 'object' ? null : (rootState.Auth.user.userId || null),
            ClientType: 4,
          },
        },
      );

      let prevDoctor;

      // eslint-disable-next-line max-len
      const prevDoctorIndex = rootState.SchedulePage.schedule.findIndex((row) => row.find((slot) => slot?.consultation?.consultationId === transferObject.consultationId));

      if (prevDoctorIndex !== -1) {
        prevDoctor = rootState.SchedulePage.doctors[prevDoctorIndex];
      }
      // eslint-disable-next-line max-len
      const targetDoctor = rootState.SchedulePage.doctors.find((doctor) => doctor.doctorSpecializationId === transferObject.targetDoctorSpecializationId);

      if (prevDoctor) {
        dispatch(types.SCHEDULE_PAGE_SCHEDULE_FETCH_BY_DOCTORS, [prevDoctor.id], { root: true });
      }

      if (targetDoctor) {
        dispatch(types.SCHEDULE_PAGE_SCHEDULE_FETCH_BY_DOCTORS, [targetDoctor.id], { root: true });
      }

      showMessage({
        type: 'success',
        message: 'Консультация успешно перенесена',
      });
    } catch (error) {
      const { response = {} } = error;
      const { data = {} } = response;

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: data.message || 'Не удалось перенести консультации',
      });

      throw (error);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CONSULTATIONS_CANCEL]({ rootState, dispatch }, cancelObject) {
    NProgress.start();

    try {
      await axios.post(
        `${APP_DOCTIS}Consultation/Cancel`,
        {
          ...cancelObject,
          IgnoreTimeoutRestriction: true,
        },
        {
          headers: {
            ApiKey: APP_DOCTIS_API_KEY,
            CrmProfileId: typeof rootState.Auth.user.userId === 'object' ? null : (rootState.Auth.user.userId || null),
            ClientType: 4,
          },
        },
      );

      let prevDoctor;
      // eslint-disable-next-line max-len
      const prevDoctorIndex = rootState.SchedulePage.schedule.findIndex((row) => row.find((slot) => slot?.consultation?.consultationId === cancelObject.consultationId));

      if (prevDoctorIndex !== -1) {
        prevDoctor = rootState.SchedulePage.doctors[prevDoctorIndex];
      }

      if (prevDoctor) {
        dispatch(types.SCHEDULE_PAGE_SCHEDULE_FETCH_BY_DOCTORS, [prevDoctor.id], { root: true });
      }

      showMessage({
        type: 'success',
        message: 'Консультация отменена',
      });
    } catch (error) {
      const { response = {} } = error;
      const { data = {} } = response;

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: data.message || 'Не удалось отменить консультацию',
      });

      throw (error);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CONSULTATIONS_CONTROL_FETCH](_, {
    status,
    consultationId,
    personName,
    type,
    problemType,
    isDeleted,
    skip,
    take,
  }) {
    NProgress.start();
    try {
      const body = {};

      if (consultationId) body.consultationId = consultationId;
      if (personName) body.personName = personName;
      if (status !== null && status !== undefined) body.status = status;
      if (isDeleted !== null && isDeleted !== undefined) body.isDeleted = isDeleted;
      if (type !== null && type !== undefined) body.type = type;
      if (problemType !== null && problemType !== undefined) body.problemType = problemType;
      if (skip !== null && skip !== undefined) body.skip = skip;
      if (take !== null && take !== undefined) body.take = take;

      const { data } = await api.post('v2/consultations/problem', body);

      return data;
    } catch (error) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить список консультаций',
      });

      throw (error);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CONSULATION_CONTROL_PROBLEM_RESOLVE]({ commit }, {
    consultationId, proccessingComment, proccessingPatientComment, status,
  }) {
    NProgress.start();
    try {
      const data = await api.post(`v2/consultations/problem/${consultationId}`, { proccessingComment, proccessingPatientComment, status });

      if (data.status === 200) {
        console.warn('updateConsultation', data);
        commit(localTypes.UPDATE_CURRENT_CONSULTATION, {
          consultationId,
          proccessingComment,
          proccessingPatientComment,
          status,
        });
      }
    } catch (error) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось обработать консультацию',
      });

      // throw (error);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CONSULATION_CONTROL_EXPORT](_, params) {
    NProgress.start();
    try {
      const { data } = await api.post('v2/consultations/problem/export', params, { responseType: 'blob' });
      saveFile(data, { name: 'export.xlsx' });
    } catch (error) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось экспортировать консультации',
      });

      // throw (error);
    } finally {
      NProgress.done();
    }
  },
};
