<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    :size="modalSize"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center w-100"
    >
      <b-spinner variant="success" />
    </div>

    <template v-else>
      <div>
        <div class="mb-2 h6">
          Тип страхования
        </div>

        <b-form-checkbox-group
          v-model="computedInsuranceTypeArray"
          :options="INSURANCE_TYPES"
          value-field="alias"
          text-field="title"
        />

        <div
          v-if="$v.doctor.settings.medicalInsuranceType.$error"
          class="mt-2 text-danger small"
        >
          {{ errorsValidation.medicalInsuranceType[0] }}
        </div>
      </div>

      <div
        v-if="doctor.settings.medicalInsuranceType != null"
        class="w-100 mt-3"
      >
        <div>
          <div class="mb-2 h6">
            Информация о враче (ФИО, пол и дата рождения)
          </div>

          <div>
            <b-input
              :value="$v.doctor.email.$model"
              placeholder="Введите email"
              title="Введите email"
              class="mt-2"
              @input="$v.doctor.email.$model = $event.toLowerCase()"
            />

            <div
              v-if="$v.doctor.email.$error"
              class="mt-2 text-danger small"
            >
              {{ errorsValidation.email[0] }}
            </div>
          </div>

          <b-row class="mt-3">
            <b-col md="6">
              <b-input
                :value="$v.doctor.login.$model"
                placeholder="Введите логин"
                title="Введите логин"
                class="mt-2"
                @input="$v.doctor.login.$model = $event.toLowerCase()"
              />

              <div
                v-if="$v.doctor.login.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.login[0] }}
              </div>
            </b-col>
            <b-col md="6">
              <b-input
                v-model="$v.doctor.password.$model"
                placeholder="Введите пароль"
                title="Введите пароль"
                class="mt-2"
              />

              <div
                v-if="$v.doctor.password.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.password[0] }}
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col md="6">
              <b-input
                v-model="$v.doctor.lastName.$model"
                placeholder="Введите фамилию"
                title="Введите фамилию"
                class="mt-2"
              />

              <div
                v-if="$v.doctor.lastName.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.lastName[0] }}
              </div>
            </b-col>
            <b-col md="6">
              <b-input
                v-model="$v.doctor.firstName.$model"
                placeholder="Введите имя"
                title="Введите имя"
                class="mt-2"
              />

              <div
                v-if="$v.doctor.firstName.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.firstName[0] }}
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col md="6">
              <b-input
                v-model="$v.doctor.middleName.$model"
                placeholder="Введите отчество"
                title="Введите отчество"
                class="mt-2"
              />

              <div
                v-if="$v.doctor.middleName.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.middleName[0] }}
              </div>
            </b-col>
            <b-col md="6">
              <!-- v-mask="'+7 (###) ### ##-##'" -->
              <b-input
                v-model="$v.doctor.phoneNumber.$model"
                v-mask="phoneMaskString"
                placeholder="Введите телефон"
                title="Введите телефон"
                class="mt-2"
                @input="changePhoneNumber"
              />

              <div
                v-if="$v.doctor.phoneNumber.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.phoneNumber[0] }}
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col md="6">
              <b-form-radio-group
                v-model="$v.doctor.sex.$model"
                :options="sexTabs"
                value-field="value"
                text-field="title"
              />

              <div
                v-if="$v.doctor.sex.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.sex[0] }}
              </div>
            </b-col>
            <b-col md="6">
              <base-date-picker
                v-model="$v.doctor.birthDate.$model"
                :max-date="maxDate"
                class="field-input"
              />

              <div
                v-if="$v.doctor.birthDate.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.birthDate[0] }}
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="block-divider w-100 my-4" />

        <b-form-checkbox
          v-model="doctor.settings.isActive"
          value="true"
          :disabled="isDisabledActivateCheckbox"
          @change="() => {
            doctor.settings.deactivationReason = null;
            doctor.settings.deactivationComment = null;
          }"
        >
          Учетная запись врача является активной
        </b-form-checkbox>

        <template v-if="!doctor.settings.isActive">
          <b-form-group
            :state="!$v.doctor.settings.deactivationReason.$error"
            :invalid-feedback="errorsValidation.deactivationReason[0]"
            class="mt-2"
          >
            <b-select
              v-model="doctor.settings.deactivationReason"
              value-field="id"
              text-field="title"
              :options="inactiveReasons"
              size="sm"
            >
              <template #first>
                <b-form-select-option :value="null">
                  Выберите причину
                </b-form-select-option>
              </template>
            </b-select>
          </b-form-group>

          <b-form-group
            :state="!$v.doctor.settings.deactivationComment.$error"
            :invalid-feedback="errorsValidation.deactivationComment[0]"
            class="mb-3"
          >
            <b-form-textarea
              v-model="doctor.settings.deactivationComment"
              placeholder="Введите комментарий"
              rows="3"
              size="sm"
            />
          </b-form-group>
        </template>

        <b-form-checkbox
          v-model="doctor.settings.hasNoDoctisSchedule"
          value="true"
          class="mt-1"
          :disabled="doctor.settings.useExternalSchedule"
        >
          Врач ведет расписание во внешней системе
        </b-form-checkbox>
        <b-form-checkbox
          v-model="doctor.settings.remoteMonitoringAllowed"
          value="true"
          class="mt-1"
        >
          Дистанционный мониторинг
        </b-form-checkbox>

        <div
          v-if="isDoctisDomain"
          class="mt-3"
        >
          <div class="mb-2 h6">
            Признак врача
          </div>

          <!-- :disabled="checkRoleAccess_(['ClinicAdministrator'])" -->
          <b-form-radio-group
            v-model="doctor.settings.signType"
            :options="filteredSignTypes"
            :disabled="!checkFeatureAccess({ name: 'Возможность изменять признак врача', url: '/doctors' })"
            value-field="id"
            text-field="title"
          />
        </div>

        <div class="mt-3">
          <div class="mb-2 h6">
            Тип видимости
          </div>

          <b-form-radio-group
            v-model="doctor.settings.accessType"
            :options="accessTypesKeys"
            value-field="id"
            text-field="title"
            @change="onChangeAccessType"
          />
        </div>

        <template v-if="doctor.settings.accessType == 3">
          <b-row class="mt-3">
            <b-col>
              <b-form-group
                label="Страховая компания"
              >
                <v-select
                  v-model="companyId"
                  :reduce="company => company.id"
                  :options="insuranceCompanies"
                  placeholder="Выберите компанию"
                  label="title"
                  @input="changeCompany"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                label="Страховая программа"
              >
                <b-form-select
                  v-model="programId"
                  :options="insurancePrograms"
                  value-field="id"
                  text-field="name"
                  @input="changeProgram"
                  @keyup.native.backspace="programId = null"
                >
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    Выберите страховую программу
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                label="Страховая подпрограмма"
                :state="!$v.doctor.settings.subprogramId.$error"
                :invalid-feedback="errorsValidation.subprogramId[0]"
              >
                <b-form-select
                  v-model="$v.doctor.settings.subprogramId.$model"
                  :options="insuranceSubprograms"
                  :state="checkErrors ? !$v.doctor.settings.subprogramId.$error : null"
                  value-field="id"
                  text-field="name"
                  @keyup.native.backspace="doctor.settings.subprogramId = null"
                >
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    Выберите страховую подпрограмму
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <div class="mt-3">
          <div class="mb-2 h6">
            Название клиники
          </div>

          <!-- :disabled="checkRoleAccess_(['ClinicAdministrator'])" -->
          <base-async-select
            v-model="clinicSelect"
            :disabled="!checkFeatureAccess({ name: 'Возможность изменять название клиники', url: '/doctors' })"
            :error="$v.doctor.clinicId.$error"
            :fetch-function="fetchClinic"
            required
            server-paginated
          />

          <div
            v-if="$v.doctor.clinicId.$error"
            class="mt-2 text-danger small"
          >
            {{ errorsValidation.clinicId[0] }}
          </div>
        </div>

        <b-row class="mt-3 d-flex">
          <b-col md="6">
            <div class="mb-2 h6">
              Снилс
            </div>

            <b-input
              v-model="doctor.snils"
              v-mask="'###-###-### ##'"
              maxlength="14"
              placeholder="Снилс"
            />
          </b-col>
          <b-col md="6">
            <div class="mb-2 h6">
              Доверенность
            </div>

            <b-input
              v-model="doctor.settings.powerOfAttorneyNumber"
              placeholder="Доверенность"
            />
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col md="6">
            <div class="mb-2 h6">
              Стоимость приема
            </div>

            <!-- :disabled="checkRoleAccess_(['ClinicAdministrator', 'Dis.monitoring.admin'])" -->
            <b-input
              v-model="$v.doctor.settings.consultationPrice.$model"
              :disabled="!checkFeatureAccess({ name: 'Возможность изменять стоимость приема', url: '/doctors' })"
              type="number"
              placeholder="Стоимость приема"
            />

            <div
              v-if="$v.doctor.settings.consultationPrice.$error"
              class="mt-2 text-danger small"
            >
              {{ errorsValidation.consultationPrice[0] }}
            </div>
          </b-col>

          <b-col md="6">
            <div class="mb-2 h6">
              Депозит
            </div>

            <!-- :disabled="checkRoleAccess_(['ClinicAdministrator', 'Dis.monitoring.admin'])" -->
            <b-input
              v-model="doctor.settings.depositPrice"
              :disabled="!checkFeatureAccess({ name: 'Возможность изменять депозит', url: '/doctors' })"
              type="number"
              placeholder="Депозит"
            />

            <!--            <div-->
            <!--              v-if="$v.doctor.settings.depositPrice.$error"-->
            <!--              class="mt-2 text-danger small"-->
            <!--            >-->
            <!--              {{ errorsValidation.depositPrice[0] }}-->
            <!--            </div>-->
          </b-col>
        </b-row>

        <div class="mt-3">
          <b-form-radio-group
            v-model="doctor.settings.priceBehavior"
            :options="PRICE_BEHAVIOR_TYPES"
            value-field="id"
            text-field="title"
          />
        </div>
        <div class="block-divider w-100 my-4" />

        <div class="mt-3">
          <div
            v-for="(v, index) in $v.doctor.doctorSpecializations.$each.$iter"
            :key="v.$model.id"
          >
            <div class="">
              <div class="mb-2 ">
                <div class="d-flex justify-content-between">
                  <div class="h6">
                    ({{ Number(index) + 1 }}) Название специальности
                  </div>

                  <b-button
                    v-if="doctor.doctorSpecializations.length > 1"
                    size="sm"
                    variant="danger"
                    @click="onClickDeleteSpecialization(index)"
                  >
                    Удалить
                  </b-button>
                </div>
              </div>

              <div>
                <base-async-select
                  :value="specializations[index]"
                  :fetch-function="fetchSpecializations"
                  class="select specialization-select"
                  placeholder="Начните вводить текст"
                  required
                  @change="changeSpecialization($event, index)"
                />

                <div
                  v-if="v.specializationId.$error"
                  class="mt-2 text-danger small"
                >
                  {{ errorsValidation.doctorSpecializations.specializationId[index][0] }}
                </div>
              </div>
            </div>

            <div class="mt-3">
              <b-form-checkbox
                v-model="v.isForAdults.$model"
                value="true"
              >
                Взрослый
              </b-form-checkbox>

              <b-form-checkbox
                v-model="v.isForChildren.$model"
                value="true"
                class="mt-1"
              >
                Детский
              </b-form-checkbox>

              <div
                v-if="v.isForAdults.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.doctorSpecializations.age[index][0] }}
              </div>
            </div>

            <div class="mt-3">
              <div class="mb-2 h6">
                Опыт работы <span class="small">(дата начала работы)</span>
              </div>

              <base-date-picker
                v-model="v.experience.$model"
                :max-date="maxDate"
                :error="!v.experience.$model"
                class="w-50 d-block"
              />

              <div
                v-if="v.isForAdults.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.doctorSpecializations.experience[index][0] }}
              </div>
            </div>

            <div class="mt-3">
              <b-form-checkbox
                v-model="v.isMain.$model"
                value="true"
              >
                Основная специальность
              </b-form-checkbox>

              <div
                v-if="v.isMain.$error"
                class="mt-2 text-danger small"
              >
                {{ errorsValidation.isMain[0] }}
              </div>
            </div>

            <div class="block-divider w-100 my-4" />
          </div>

          <div class="mt-3">
            <b-button
              variant="primary"
              size="sm"
              @click="onClickAddSpecialization"
            >
              Добавить специализацию
            </b-button>
          </div>
        </div>

        <div class="mt-3">
          <div class="mb-2 h6">
            Тип связи
          </div>

          <b-row>
            <b-col cols="2">
              <b-form-checkbox
                v-model="onlyTrue"
                disabled
              >
                Текст
              </b-form-checkbox>
            </b-col>
            <b-col cols="2">
              <b-form-checkbox
                v-model="doctor.settings.isAudioEnabled"
              >
                Аудио
              </b-form-checkbox>
            </b-col>
            <b-col cols="2">
              <b-form-checkbox
                v-model="doctor.settings.isVideoEnabled"
              >
                Видео
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>

        <div class="mt-3">
          <div class="mb-2 h6">
            Регион и тип прикрепления
          </div>

          <base-checkbox-select
            :container-class="'crm-form-field-region-container'"
            class="crm-checkbox-select"
            placeholder="Выберите регион"
            fluid
          >
            <template
              v-slot:default="{ query }"
            >
              <base-checkbox-list
                v-model="doctor.regions"
                :query="query"
                :choices="regionChoices"
              />
            </template>
          </base-checkbox-select>

          <div class="mt-2">
            <SelectedItem
              v-for="region in doctor.regions"
              :key="region.id"
              class="mb-1"
              @remove="deleteRegion(region)"
            >
              {{ region.title }}
            </SelectedItem>
          </div>
        </div>

        <div class="block-divider w-100 my-4" />

        <div class="mt-3 w-100">
          <div class="d-flex align-items-center mb-4">
            <div class="mb-2 h6">
              Прикрепленные очереди
            </div>

            <b-button
              :variant="queueTableShow ? 'secondary' : 'primary'"
              class="ml-auto"
              size="sm"
              @click="toggleQueueTableShow"
            >
              {{ queueTableShow ? 'Скрыть' : 'Добавить очередь' }}
            </b-button>
          </div>

          <b-table-simple
            v-if="doctor.queues.length"
            bordered
            small
          >
            <b-tbody>
              <b-tr
                v-for="queue in doctor.queues"
                :key="queue.id"
              >
                <b-td>
                  {{ queue.name }}
                </b-td>
                <b-td>
                  {{ queue.description || 'Нет описания' }}
                </b-td>
                <b-td style="width: 40px">
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="onQueueRemove(queue.id)"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div
            v-else
            class="text-secondary"
          >
            Очереди не добавлены
          </div>

          <template v-if="queueTableShow">
            <b-form-input
              v-model="queueSearchString"
              type="text"
              debounce="500"
              placeholder="Поиск очереди по названию"
              size="sm"
              trim
              class="mb-2 mt-4"
            />

            <div
              v-if="filteredQueues.length"
              style="max-height: 200px; overflow-y: auto;"
            >
              <b-table-simple
                small
                bordered
                class="mb-0"
              >
                <b-tbody>
                  <b-tr
                    v-for="queue in filteredQueues"
                    :key="queue.id"
                  >
                    <b-td>
                      {{ queue.name }}
                    </b-td>
                    <b-td>
                      {{ queue.description || 'Нет описания' }}
                    </b-td>
                    <b-td style="width: 40px">
                      <b-button
                        variant="success"
                        size="sm"
                        @click="onQueueAdd(queue)"
                      >
                        <b-icon icon="plus" />
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>

            <small
              v-if="queueSearchString && !filteredQueues.length"
              class="ml-2"
            >
              Очередь не найден
            </small>
          </template>
        </div>
      </div>
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { formatISO } from 'date-fns';
import { validationMixin } from 'vuelidate';
import { mixinRoles } from '@/mixins';
import { phoneMixins } from '@/mixins/phoneMixins';
import { api } from '@/helpers/api';
import { DOCTOR_ACCESS_TYPE, POLICY_TYPE, DOCTOR_INACTIVE_REASONS } from '@/helpers/consts';
import { minLength, required } from 'vuelidate/lib/validators';
import { conformToMask } from 'vue-text-mask';
import { APP_SETTINGS } from '@/app.config';
import Bus from '@/eventBus';
import {
  dateWithoutTime, showMessage,
} from '@/helpers/utils';

import {
  BaseAsyncSelect,
  BaseCheckboxList,
  BaseCheckboxSelect,
  BaseDatePicker,
} from '@/components/base';

import SelectedItem from '@/components/common/ui/SelectedItem';

const INSURANCE_TYPES = [
  {
    alias: 'OMS',
    title: 'ОМС',
  },
  {
    alias: 'DMS',
    title: 'ДМС',
  },
];

const SIGN_TYPES = [
  {
    id: 0,
    title: 'Нет признака',
  },
  {
    id: 1,
    title: 'Мать и дитя',
  },
  {
    id: 2,
    title: 'Foodfessional',
  },
  {
    id: 3,
    title: 'Евоген',
  },
  {
    id: 4,
    title: 'РТ Здоровье',
  },
];

const PRICE_BEHAVIOR_TYPES = [
  {
    id: 0,
    title: 'Полис покрывает по лимиту подпрограммы',
  },
  {
    id: 1,
    title: 'Полис всегда покрывает',
  },
  {
    id: 2,
    title: 'Полис никогда не покрывает',
  },
];

export default {
  name: 'DoctorEditModal',
  components: {
    BaseAsyncSelect,
    BaseCheckboxSelect,
    BaseCheckboxList,
    BaseDatePicker,
    SelectedItem,
  },
  mixins: [validationMixin, mixinRoles, phoneMixins],
  props: {
    doctorId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Добавление врача',
    },
  },
  data() {
    return {
      isOpen: true,
      phoneMaskString: '+7 (###) ### ##-##',
      phoneMask: ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, '-', /\d/, /\d/],
      snilsMask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
      DOCTOR_ACCESS_TYPE,
      isLoading: false,
      inactiveReasons: DOCTOR_INACTIVE_REASONS,
      onlyTrue: true,
      maxDate: new Date(),
      clinic: null,
      specializations: [null],
      isSaving: false,
      checkErrors: false,
      hadMidId: false,
      midDisabled: false,
      regionChoices: {
        title: 'Выбрать все',
        choices: [],
      },
      sexTabs: [
        {
          title: 'Мужской',
          value: 1,
        },
        {
          title: 'Женский',
          value: 2,
        },
      ],
      INSURANCE_TYPES,
      insuranceTypeArray: [],

      SIGN_TYPES,
      PRICE_BEHAVIOR_TYPES,
      companyId: null,
      programId: null,
      showMidClinicStatus: true,
      midClinicsSearch: false,
      midClinicSearched: false,
      midClinics: [],
      insuranceData: [],
      insuranceCompanies: [],
      insurancePrograms: [],
      insuranceSubprograms: [],
      doctor: {
        id: 0,
        userId: 0,
        lastName: '',
        middleName: '',
        firstName: '',
        phoneNumber: '+7 (',
        birthDate: null,
        sex: null,
        email: '',
        login: '',
        password: '',
        clinicId: null,
        regions: [],
        queues: [],
        settings: {
          id: 0,
          isAudioEnabled: false,
          isVideoEnabled: false,
          consultationPrice: 0,
          depositPrice: 0,
          useExternalSchedule: false,
          midServiceId: 0,
          signType: 0,
          medicalInsuranceType: null,
          accessType: 0,
          isActive: true,
          priceBehavior: 0,
          hasNoDoctisSchedule: false,
          remoteMonitoringAllowed: false,
          powerOfAttorneyNumber: '',
          subprogramId: null,
          deactivationComment: null,
          deactivationReason: null,
        },
        doctorSpecializations: [
          {
            id: Math.floor(Math.random() * 1001),
            specializationId: null,
            experience: null,
            isForAdults: true,
            isForChildren: true,
            isMain: false,
          },
        ],
      },
      queueTableShow: false,
      queueSearchString: '',
      queues: [],
    };
  },
  validations() {
    return {
      doctor: {
        email: {
          required: !this.doctorId ? required : () => true,
          mailValidation: (val) => !(/[ ]/.test(val)),
          // required,
        },
        login: {
          required: !this.doctorId ? required : () => true,
          correctValue: (val) => !(/[а-яёА-ЯЁ ]/.test(val)),
          // required,
        },
        password: {
          required: !this.doctorId ? required : () => true,
          minLength: !this.doctorId ? minLength(6) : () => true,
        },
        firstName: {
          required,
          // onlyRussian: (value) => hasRussianName(value),
        },
        lastName: {
          required
          /* onlyRussian: (value) => hasRussianName(value) */,
        },
        middleName: {},
        phoneNumber: {
          required,
          isValid: (value) => /^\+\d \(\d{3}\) \d{3} \d{2}-\d{2}$/i.test(value),
        },
        birthDate: {
          required,
        },
        sex: {
          required,
        },
        clinicId: {
          required,
        },
        settings: {
          deactivationReason: {
            required: this.doctor.settings.isActive ? () => true : required,
          },
          deactivationComment: {
            required: this.doctor.settings.isActive ? () => true : required,
          },
          signType: {
            required: this.isDoctisDomain ? required : () => true,
          },
          consultationPrice: {
            required,
            isValid: (value) => value >= 0,
          },
          midServiceId: {
            required: this.doctor.settings.signType === 1 ? required : () => true,
            isValid: this.doctor.settings.signType === 1 ? (value) => value >= 0 : () => true,
          },
          medicalInsuranceType: {
            required,
          },
          subprogramId: {
            required: Number(this.doctor.settings.accessType) === 3 ? required : () => true,
          },
        },
        doctorSpecializations: {
          isValid: (value) => value.length > 0,
          $each: {
            experience: {
              required,
            },
            specializationId: {
              required,
            },
            isMain: {
              isAlone: () => this.doctor.doctorSpecializations.reduce((acc, spec) => (spec.isMain ? acc + 1 : acc), 0) === 1,
            },
            isForChildren: {
              isSelectedOne: (value, spec) => value || spec.isForAdults,
            },
            isForAdults: {
              isSelectedOne: (value, spec) => value || spec.isForChildren,
            },
          },
        },
      },
    };
  },
  computed: {
    modalSize() {
      if (this.selectedMidClinic?.clinicTitle.length > 65) {
        return 'xl';
      }

      return 'lg';
    },
    isDisabledActivateCheckbox() {
      // return !this.checkFeatureAccess({ name: 'Возможность ставить галочку - Учетная запись врача является активной', url: '/doctors' });
      return false;
    },
    selectedMidClinic() {
      return this.midClinics?.find((item) => item.serviceId === this.midServiceId);
    },
    midServiceId: {
      get() {
        return Number(this.$v.doctor.settings.midServiceId.$model);
      },
      set(newVal) {
        this.$v.doctor.settings.midServiceId.$model = String(newVal);
      },
    },
    filteredSignTypes() {
      if (this.doctorId) {
        return this.SIGN_TYPES;
      }

      // this.checkRoleAccess_(['Dis.monitoring.admin'])
      if (this.checkFeatureAccess({ name: 'Признак врача - только "Нет признака"', url: '/doctors' })) {
        return [this.SIGN_TYPES[0]];
      }

      return this.SIGN_TYPES;
    },
    fullName() {
      return `${this.doctor.lastName} ${this.doctor.firstName} ${this.doctor.middleName}`;
    },
    isDoctisDomain() {
      return !APP_SETTINGS || APP_SETTINGS === 'default';
    },
    errorsValidation() {
      const errors = {};

      errors.email = [];
      if (!this.$v.doctor.email.required) {
        errors.email.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.email.mailValidation) {
        errors.email.push('Не должно быть пробелов');
      }

      errors.login = [];
      if (!this.$v.doctor.login.required) {
        errors.login.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.login.correctValue) {
        errors.login.push('Значение некорректно');
      }

      errors.password = [];
      if (!this.$v.doctor.password.required) {
        errors.password.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.password.minLength) {
        errors.password.push('Поле должно содержать не менее 6 символов');
      }

      errors.firstName = [];
      if (!this.$v.doctor.firstName.required) {
        errors.firstName.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.firstName.onlyRussian) {
        errors.firstName.push('Доступно: русский язык и дефис');
      }

      errors.lastName = [];
      if (!this.$v.doctor.lastName.required) {
        errors.lastName.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.lastName.onlyRussian) {
        errors.lastName.push('Доступно: русский язык и дефис');
      }

      errors.middleName = [];
      if (!this.$v.doctor.middleName.onlyRussian) {
        errors.middleName.push('Доступно: русский язык и дефис');
      }

      errors.phoneNumber = [];
      if (!this.$v.doctor.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.phoneNumber.isValid) {
        errors.phoneNumber.push('Некорректное значение поля');
      }

      errors.birthDate = [];
      if (!this.$v.doctor.birthDate.required) {
        errors.birthDate.push('Поле не может быть пустым');
      }

      errors.sex = [];
      if (!this.$v.doctor.sex.required) {
        errors.sex.push('Поле не может быть пустым');
      }

      errors.clinicId = [];
      if (!this.$v.doctor.clinicId.required) {
        errors.clinicId.push('Поле не может быть пустым');
      }

      errors.signType = [];
      if (!this.$v.doctor.settings.signType.required) {
        errors.signType.push('Поле не может быть пустым');
      }

      errors.deactivationReason = [];
      if (!this.$v.doctor.settings.deactivationReason.required) {
        errors.deactivationReason.push('Поле не может быть пустым');
      }

      errors.deactivationComment = [];
      if (!this.$v.doctor.settings.deactivationComment.required) {
        errors.deactivationComment.push('Поле не может быть пустым');
      }

      // errors.depositPrice = [];
      // if (!this.$v.doctor.settings.depositPrice.required) {
      //   errors.depositPrice.push('Поле не может быть пустым');
      // }
      // if (!this.$v.doctor.settings.depositPrice.isValid) {
      //   errors.depositPrice.push('Некорректное значение поля');
      // }

      errors.consultationPrice = [];
      if (!this.$v.doctor.settings.consultationPrice.required) {
        errors.consultationPrice.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.settings.consultationPrice.isValid) {
        errors.consultationPrice.push('Некорректное значение поля');
      }

      errors.midServiceId = [];
      if (!this.$v.doctor.settings.midServiceId.required) {
        errors.midServiceId.push('Поле не может быть пустым');
      }
      if (!this.$v.doctor.settings.midServiceId.isValid) {
        errors.midServiceId.push('Некорректное значение поля');
      }

      errors.medicalInsuranceType = [];
      if (!this.$v.doctor.settings.medicalInsuranceType.required) {
        errors.medicalInsuranceType.push('Поля не могут быть пустыми');
      }

      errors.subprogramId = [];
      if (!this.$v.doctor.settings.subprogramId.required) {
        errors.subprogramId.push('Поле не может быть пустым');
      }

      errors.isMain = [];
      if (!this.$v.doctor.doctorSpecializations.$each[0]?.isMain.isAlone) {
        errors.isMain.push('Необходимо пометить одну специальность как основную');
      }

      errors.doctorSpecializations = {};

      errors.doctorSpecializations.experience = this.doctor.doctorSpecializations.map((item, index) => {
        const localErrors = [];
        if (!this.$v.doctor.doctorSpecializations.$each[index].experience.required) {
          localErrors.push('Поле не может быть пустым');
        }
        return localErrors;
      });

      errors.doctorSpecializations.age = this.doctor.doctorSpecializations.map((item, index) => {
        const localErrors = [];
        if (!this.$v.doctor.doctorSpecializations.$each[index].isForChildren.isSelectedOne) {
          localErrors.push('Необходимо выбрать хотя бы один вариант');
        }
        return localErrors;
      });

      errors.doctorSpecializations.specializationId = this.doctor.doctorSpecializations.map((item, index) => {
        const localErrors = [];
        if (!this.$v.doctor.doctorSpecializations.$each[index].specializationId.required) {
          localErrors.push('Поле не может быть пустым');
        }
        return localErrors;
      });
      return errors;
    },
    computedInsuranceTypeArray: {
      get() {
        return this.insuranceTypeArray;
      },
      set(newValue) {
        this.insuranceTypeArray = newValue;

        const hasOMS = newValue.includes('OMS');
        const hasDMS = newValue.includes('DMS');

        let medicalInsuranceType;
        if (hasOMS && hasDMS) {
          medicalInsuranceType = POLICY_TYPE.codeType('All');
        } else if (hasOMS) {
          medicalInsuranceType = POLICY_TYPE.codeType('OMS');
        } else if (hasDMS) {
          medicalInsuranceType = POLICY_TYPE.codeType('DMS');
        } else {
          medicalInsuranceType = null;
        }

        this.$v.doctor.settings.medicalInsuranceType.$model = medicalInsuranceType;
      },
    },
    clinicSelect: {
      get() {
        return this.clinic;
      },
      set(newVal) {
        this.clinic = newVal;

        this.doctor.clinicId = newVal && newVal.value;
      },
    },
    accessTypesKeys() {
      return Object.entries(DOCTOR_ACCESS_TYPE).map(([id, title]) => ({
        id, // можно сделать Number(id), но я решил оставить так
        title,
      }));
    },
    filteredQueues() {
      return this.queues.filter((q) => {
        if (this.queueSearchString && !q.name?.toLowerCase().includes(this.queueSearchString.toLowerCase())) return false; // search by queue name from queueSearchString
        return !this.doctor.queues.find((qq) => qq.id === q.id); // return false if queue added to userdata
      });
    },
    // disabledFields() {
    //   return {
    //     computedInsuranceTypeArray: this.checkRoleAccess_(['ClinicAdministrator', 'Operator.gostelemed']),
    //   };
    // },
  },
  async created() {
    this.isLoading = true;

    // this.checkRoleAccess_(['Dis.monitoring.operator', 'Dis.monitoring.admin']) Operator.gostelemed ClinicAdministrator
    if (this.checkFeatureAccess({ name: 'При открытии - тип страхования ОМС', url: '/doctors' })) {
      this.computedInsuranceTypeArray = ['OMS'];
    }

    if (this.checkFeatureAccess({ name: 'При открытии - тип страхования ДМС', url: '/doctors' })) {
      this.computedInsuranceTypeArray = ['DMS'];
    }

    // this.checkRoleAccess_(['Dis.monitoring.operator', 'Dis.monitoring.admin'])
    if (this.checkFeatureAccess({ name: 'При открытии - тип страхования заблокирован', url: '/doctors' })) {
      this.INSURANCE_TYPES = INSURANCE_TYPES.map((item) => {
        item.disabled = true;

        return item;
      });
    }

    this.$store.dispatch(this.$types.DOCTOR_FETCH_REGIONS).then((regions) => {
      this.regionChoices.choices = regions.map((region) => ({ ...region, title: region.name }));
    });
    this.queues = await this.$store.dispatch(this.$types.QUEUES_FETCH);
    this.insuranceData = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.insuranceCompanies = this.insuranceData.map(({ company: { id, title } }) => ({ id, title }));

    if (this.doctorId) {
      try {
        const doctor = await this.$store.dispatch(this.$types.DOCTOR_FETCH, this.doctorId);
        const emptyClinicId = '00000000-0000-0000-0000-000000000000';
        const decodedInsuranceType = POLICY_TYPE.decodeType(doctor.settings.medicalInsuranceType);

        if (decodedInsuranceType === 'All') {
          this.insuranceTypeArray = ['OMS', 'DMS'];
        } else if (decodedInsuranceType === 'OMS') {
          this.insuranceTypeArray = ['OMS'];
        } else if (decodedInsuranceType === 'DMS') {
          this.insuranceTypeArray = ['DMS'];
        }

        this.doctor = {
          ...doctor,
          phoneNumber: doctor.phoneNumber ? conformToMask(doctor.phoneNumber, this.phoneMask).conformedValue : '+7 (',
          birthDate: new Date(doctor.birthDate),
          clinicId: doctor.clinicId === emptyClinicId ? null : doctor.clinicId,
          regions: doctor.regions.map((region) => ({ ...region, title: region.name })),
          doctorSpecializations: doctor.doctorSpecializations.map((specialization) => ({
            ...specialization,
            id: Math.floor(Math.random() * 1001),
            experience: new Date(specialization.experience),
          })),
        };

        if (this.doctor.mainDoctorSpecialization) {
          this.doctor.mainDoctorSpecialization = {
            ...this.doctor.mainDoctorSpecialization,
            experience: new Date(doctor.mainDoctorSpecialization.experience),
          };
        }

        this.clinic = doctor.clinicId === emptyClinicId ? null : {
          name: doctor.clinicName,
          value: doctor.clinicId,
        };

        this.specializations = this.doctor.doctorSpecializations.map((specialization) => ({
          name: this.doctor.specializations.find((spec) => spec.id === specialization.specializationId).title,
          value: specialization.specializationId,
        }));

        if (this.doctor.settings.subprogramId) {
          const { data } = await api.get(`v2/insuranceCompanies/programs/subprogram/${this.doctor.settings.subprogramId}`);

          this.companyId = data.company.id;
          this.programId = data.program.id;
          this.setInsuranceEntities();
        }
        this.hadMidId = doctor.settings.signType === 1;
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    }

    // this.checkRoleAccess_(['ClinicAdministrator'])
    if (!this.doctorId && this.checkFeatureAccess({
      name: 'При открытии произвести поиск клиники, выбрать первую в поле "Название клиники"',
      url: '/doctors',
    })) {
      const clinic = (await this.fetchClinic({ take: 10 }))[0];
      this.clinicSelect = clinic;
      // this.computedInsuranceTypeArray = ['OMS']; условие стоит выше
    }

    // if (!this.doctorId && this.checkRoleAccess_(['Operator.gostelemed'])) {
    //   // this.computedInsuranceTypeArray = ['OMS']; условие стоит выше
    // }
    this.isLoading = false;
  },
  methods: {
    async showMidClinic() {
      this.showMidClinicStatus = true;
    },
    async changePhoneNumber(newValue) {
      const newPhoneNumber = await this.formatingPhoneNumber(newValue);
      if (newPhoneNumber) {
        this.$v.doctor.phoneNumber.$model = newPhoneNumber;
      }
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    setInsuranceEntities() {
      try {
        if (!this.companyId) return;

        const companyObject = this.insuranceData
          .filter(({ company: { id } }) => id === this.companyId)[0];

        const programs = companyObject.programsWithSubprograms.map(({ program: { id, name } }) => ({ id, name }));
        this.insurancePrograms = programs;

        if (!this.programId) return;

        const programObject = companyObject.programsWithSubprograms.filter(({ program: { id } }) => id === this.programId)[0];
        this.insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name }) => ({ id: subprogramId, name }));
      } catch (e) {
        console.error(e);
      }
    },
    changeCompany() {
      this.programId = null;
      this.doctor.settings.subprogramId = null;
      this.insurancePrograms = [];
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeProgram() {
      this.doctor.settings.subprogramId = null;
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    onChangeAccessType(accessType) {
      if (accessType !== 3) {
        this.$v.doctor.settings.subprogramId.$model = null;
      }
    },
    async onClickSave() {
      this.$v.$touch();
      this.checkErrors = true;

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        console.log(this.$v);
        console.log(this.$v.error);
        return;
      }

      this.isSaving = true;
      // удаляем рандомные id
      const doctorSpecializations = this.doctor.doctorSpecializations.map((item) => {
        const newItem = { ...item };
        delete newItem.id;

        return newItem;
      });

      const doctor = {
        ...this.doctor,
        phoneNumber: `+${this.doctor.phoneNumber.replace(/\D/g, '')}`,
        birthDate: dateWithoutTime(formatISO(this.doctor.birthDate)),
        regions: this.doctor.regions.map((region) => ({
          id: region.id,
          name: region.name,
          code: region.code,
        })),
        doctorSpecializations: doctorSpecializations.map((specialization) => ({
          ...specialization,
          experience: dateWithoutTime(formatISO(specialization.experience)),
        })),
        mainDoctorSpecialization: doctorSpecializations.map((specialization) => ({
          ...specialization,
          experience: dateWithoutTime(formatISO(specialization.experience)),
        })).find((specializaion) => !!specializaion.isMain),
        userInfo: {
          ...this.doctor.userInfo,
          email: this.doctor.email,
          userName: this.doctor.login,
          phoneNumber: `+${this.doctor.phoneNumber.replace(/\D/g, '')}`,
        },
      };

      // что-бы через html-ку не меняли значение
      doctor.settingsuseExternalSchedule = this.midDisabled ? true : this.doctor.settings.useExternalSchedule;

      try {
        if (this.doctorId) {
          await this.$store.dispatch(this.$types.DOCTOR_UPDATE, doctor);

          Bus.$emit('doctors:update', this.doctorId);
        } else {
          await this.$store.dispatch(this.$types.DOCTOR_CREATE, doctor);
        }

        this.onClose();
      } catch (e) {
        console.warn(e);
      } finally {
        this.isSaving = false;
      }
    },
    onClickAddSpecialization() {
      const newSpecialization = {
        id: Math.floor(Math.random() * 1001),
        specializationId: null,
        experience: null,
        isForAdults: true,
        isForChildren: true,
        isMain: false,
      };

      this.specializations.push({
        name: '',
        value: null,
      });
      this.$v.doctor.doctorSpecializations.$model.push(newSpecialization);
    },
    onClickDeleteSpecialization(index) {
      this.doctor.doctorSpecializations.splice(index, 1);
      this.doctor.specializations.splice(index, 1);
      this.specializations.splice(index, 1);
    },
    changeSpecialization(spec, index) {
      // При удалении
      if (!spec) {
        this.specializations.splice(index, 1, spec);
        this.$v.doctor.doctorSpecializations.$model[index].specializationId = null;

        return;
      }

      // Проверка есть ли уже такая специальность. Если есть - выводим ошибку
      if (this.specializations.some((item) => {
        if (item) {
          return item.id === spec.id
            || item.value === spec.id;
        }

        return false;
      })) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: 'Такая специальность уже выбрана',
        });
        return;
      }

      // Добавелние специальности
      this.specializations.splice(index, 1, spec);
      this.$v.doctor.doctorSpecializations.$model[index].specializationId = spec && spec.id;
    },
    toggleQueueTableShow() {
      this.queueTableShow = !this.queueTableShow;
    },
    onQueueRemove(id) {
      this.doctor.queues = this.doctor.queues.filter((q) => q.id !== id);
    },
    onQueueAdd(queue) {
      if (this.doctor.queues.find((q) => q.id === queue.id)) return; // return if queue already in array
      if (queue) this.doctor.queues.push(queue);
    },
    async fetchClinic({ query, skip, take }) {
      const clinics = await this.$store.dispatch(this.$types.CLINICS_FETCH, {
        skip,
        take,
        filter: {
          query,
          network: null,
          city: null,
        },
      });

      return clinics.map((clinic) => ({ value: clinic.id, name: clinic.title }));
    },
    async fetchSpecializations(query) {
      const data = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);

      return data;
    },
    deleteRegion(region) {
      this.doctor.regions = this.doctor.regions.filter((item) => item.id !== region.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.block-divider {
  display: block;
  height: 1px;
  background: -webkit-gradient(linear,right top,left top,from(#ffffff14),color-stop(50%,#545454),to(#ffffff14)),#eeeeee1f;
  background: linear-gradient(270deg,#ffffff14,#545454 50%,#ffffff14),#eeeeee1f;
}
</style>
