import NProgress from 'nprogress/nprogress';
import jwtDecode from 'jwt-decode';
import { removeNamespaces } from '@/helpers/utils';
import * as types from '@/store/types';
import { api, createApi } from '@/helpers/api';
import { ROUTE_COMPONENTS } from '@/helpers/consts';
import router from '@/router';

import { APP_HOST } from '@/app.config';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.LOGIN]({ commit, dispatch }, {
    login, password, saveAuth, jwtToken,
  }) {
    NProgress.start();

    try {
      let token = jwtToken;

      if (!jwtToken) {
        const { data: { token: newToken } } = await api.post('Account/Login/', { login, password });
        token = newToken;
      }

      const decodedToken = jwtDecode(token);
      const loginDecoded = decodedToken.sub;
      const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

      if (saveAuth) {
        localStorage.setItem('token', token);
        localStorage.setItem('role', role);
        localStorage.setItem('login', loginDecoded);
      } else {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('role', role);
        sessionStorage.setItem('login', loginDecoded);
      }

      commit(localTypes.ROLE_SET, role);
      commit(localTypes.TOKEN_SET, token);
      commit(localTypes.LOGIN_SET, loginDecoded);
      createApi(token);
      dispatch(localTypes.USER_FETCH);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LOGOUT]({ commit }) {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('role');
    localStorage.clear();
    commit(localTypes.TOKEN_SET, null);
    commit(localTypes.ROLE_SET, null);
    router.push('/login');
  },
  async [localTypes.USER_FETCH]({ commit, dispatch }) {
    commit(localTypes.USER_LOADING_SET, true);

    try {
      const { data: user, status } = await api.get('Account/');

      // Разлогин для удаленных аккаунтов
      if (status === 204) return dispatch(localTypes.LOGOUT);
      // Разлогин для старой админки
      if (user.login === 'admin@crm.doctis.ru') return dispatch(localTypes.LOGOUT);

      // Проверка для страницы направления на консультацию через импорт
      const filteredPages = user.roles[0].pages.filter((p) => {
        if (user.roles[0].name !== 'Administrator' && p.url === '/referral-duty-consultation-import') return user.canReferralToDuty; // Если у юзера разблокирована страница направления на консультацию через импорт
        return true;
      });

      const userData = { ...user };
      userData.roles[0].pages = [...filteredPages];

      commit(localTypes.USER_SET, userData);

      // set routes
      const { roles } = user;
      const childrenPages = [];
      const addChildrenPages = (page) => {
        if (page.url && page.url !== '#') childrenPages.push(page);
        if (page?.pages) page.pages.forEach(addChildrenPages);
      };

      roles[0].pages.forEach(addChildrenPages);

      const redirectUrl = childrenPages.filter((p) => p.isMenuItem).sort((a, b) => a.index - b.index)[0].url; // first menu item
      const childrenRoutes = childrenPages.map(({ url }) => ROUTE_COMPONENTS[url]).filter((p) => p);
      const routes = [
        {
          path: '/',
          component: () => import('@/layouts/MainLayout'),
          redirect: () => redirectUrl,
          meta: { authRequired: true },
          children: [...childrenRoutes],
        },
      ];

      routes.push({ path: '*', redirect: '/' });
      routes.forEach((r) => router.addRoute(r));

      return user;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      commit(localTypes.USER_LOADING_SET, false);
    }
  },
  async [localTypes.LOGIN_ESIA](_, { redirectUrl }) {
    NProgress.start();

    try {
      window.location.href = `${APP_HOST}Account/esia/login?redirectUrl=${redirectUrl}`;
    } catch (error) {
      console.log(error.response.headers);
    } finally {
      NProgress.done();
    }
  },
};
