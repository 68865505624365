import * as constants from '@/helpers/consts';
import * as types from '@/store/types';
import { APP_SENTRY_DSN, APP_HOST } from '@/app.config';

import { createApi } from '@/helpers/api';
import AxiosUpdatedClient from '@/services/core/axios/axios';

import Vue from 'vue';

import VTooltip from 'v-tooltip';
import vSelect from 'vue-select';
import customScroll from 'vuescroll/dist/vuescroll-native';
import Vuedals from 'vuedals';
import Vuelidate from 'vuelidate';
import Notifications from 'vue-notification';
import VueDragDrop from 'vue-drag-drop';
import YmapPlugin from 'vue-yandex-maps';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueMask from 'v-mask';
import Maska from 'maska';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import VCalendar from 'v-calendar';

import '@/services/call/index';
import NotificationService from '@/services/notification/notification.service';

import RemoteMonitoringPlugin from '@doctis.front/library.vuecomponent.adminmonitoring';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import IconSelectArrow from '@/assets/images/select_arrow.svg';

import App from './App';
import router from './router';
import store from './store';

import '@/assets/css/tailwind.css';
import '@/assets/css/ckeditor-reset.css';
import '@doctis.front/doctis.designsystem/dist/index.css';
import 'normalize.css';
import '@/assets/fonts/Monteserrat/stylesheet.css';
import '@/assets/fonts/Muller/stylesheet.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.prototype.$const = constants;
Vue.prototype.$types = types;

const apiClient = {
  client: AxiosUpdatedClient.instance,
  v2options: {
    baseUrl: `${APP_HOST}v2/`,
  },
  v1options: {
    baseUrl: APP_HOST,
  },
};

Vue.config.productionTip = false;
Vue.use(VTooltip);
Vue.use(Vuedals);
Vue.use(Vuelidate);
Vue.use(VCalendar);
Vue.use(Notifications);
Vue.use(VueDragDrop);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMask);
Vue.use(Maska);
Vue.component('multiselect', Multiselect);

const notificationService = new NotificationService(Vue);
Vue.prototype.$notificationService = notificationService;

Vue.use(RemoteMonitoringPlugin, {
  apiClient,
  settings: 'gostelemed',
  notificationService,
});

Vue.use(YmapPlugin, {
  apiKey: '89afe9fc-f9ac-48d8-b658-95efae67c17e',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
});

Vue.use(customScroll, {
  name: 'customScrollbar',
  ops: {
    vuescroll: {
      mode: 'native',
      // sizeStrategy: 'number',
    },
    scrollPanel: {
      scrollingX: false,
      scrollingY: true,
    },
    rail: {
      background: '#D5D9E5',
      gutterOfSide: '1.5px',
      gutterOfEnds: '10px',
      opacity: 0.4,
    },
    bar: {
      onlyShowBarOnScroll: true,
      keepShow: false,
      background: '#ADB0B8',
      minSize: 0.15,
    },
  },
});

vSelect.props.components.default = () => ({
  OpenIndicator: IconSelectArrow,
});

Vue.component('v-select', vSelect);

Sentry.init({
  Vue,
  dsn: APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  async created() {
    const error = new URLSearchParams(window.location.search)?.get('error');
    const token = localStorage.getItem('token')
      || sessionStorage.getItem('token')
      || new URLSearchParams(window.location.search)?.get('token');
    const role = localStorage.getItem('role') || sessionStorage.getItem('role');
    const login = localStorage.getItem('login') || sessionStorage.getItem('login');

    if (token && role && login) {
      this.$store.commit(this.$types.TOKEN_SET, token);
      this.$store.commit(this.$types.ROLE_SET, role);
      this.$store.commit(this.$types.LOGIN_SET, login);

      createApi(token);
      try {
        await this.$store.dispatch(this.$types.USER_FETCH);
      } catch (err) {
        this.$store.dispatch(this.$types.LOGOUT);
        console.warn(err);
        throw err;
      }
      return;
    }

    if (error || (token && !role && !login)) return;

    this.$store.dispatch(this.$types.LOGOUT);
  },
  render: (h) => h(App),
}).$mount('#app');
